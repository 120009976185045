import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

const UniversityPortal_AllEquivalences = () => {

  const universityId = "1";

  
  const [universityName, setUniversityName] = useState("");
  const [uniqueEquivalences, setUniqueEquivalences] = useState([]);
  
 
    useEffect(() => {
    fetchUniversityName();
    fetchUniqueEquivalences(universityId);
  }, []);

    const fetchUniversityName = async () => {
        try {
        const response = await axios.get(`/UniversityPortal/UniversityName/${universityId}`);
        setUniversityName(response.data);
        } catch (error) {
        console.error("Error fetching university name:", error);
        }
    };

    const fetchUniqueEquivalences = async (universityId) => {
        try {
            //const responseUniversityID = await axios.get(`/UniversityPortal/UniversityID/${universityId}`);
            //const targetUniversityId = responseUniversityID.data;

            //const responseCourseID = await axios.get(`/UniversityPortal/CourseId/${selectedCourse2}`);
            //const targetCourseId = responseCourseID.data;

            const response = await axios.get(`/UniversityPortal/UniqueEquivalences/${universityId}`);
            setUniqueEquivalences(response.data);
            console.log("Unique Equivalences", response.data);
        } catch (error) {
        console.error('Error fetching unique equivalences:', error);
        }
    };

    return (
        <div className="university-portal-all-equivalences">
        <div className="container">
            <h1 className="subtitle">Welcome to the University YachaY Portal</h1>
            <div className="frame">
                <div className="frame-content">
                <h1 className="subtitle-uni">{universityName}</h1> 
                </div>
            </div>                       
            <hr className="divider" />
            <h1>List of Unique Equivalences</h1>
            <h1 className="description">View a list of all registered unique equivalences for {universityName}</h1>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>Equivalence ID</th>                
                    <th>Source Name</th>                
                    <th>Source Course Name</th>                
                    <th>Target Name</th>                
                    <th>Target Course Name</th>
                    <th>Date</th>
                </tr>
                </thead>
                {uniqueEquivalences && Array.isArray(uniqueEquivalences) && (
                <tbody>
                    {uniqueEquivalences.map((uniqueEquivalence) => (
                    <tr key={uniqueEquivalence.ID_YachaY_Equivalence}>
                        <td>{uniqueEquivalence.ID_YachaY_Equivalence}</td>                    
                        <td>{uniqueEquivalence.associatedSourceName}</td>                    
                        <td>{uniqueEquivalence.associatedSourceCourseName}</td>                   
                        <td>{uniqueEquivalence.associatedTargetName}</td>                    
                        <td>{uniqueEquivalence.associatedTargetCourseName}</td>
                        <td>{uniqueEquivalence.Date}</td>
                    </tr>
                    ))}
                </tbody>
                )}
            </table>        
        </div>
    )};

    export default UniversityPortal_AllEquivalences;
