import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './App.css';

const TabSelector = () => {
  const navStyle = {
    backgroundColor: '#f1f1f1',
    padding: '10px',
  };

  const ulStyle = {
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '0',
    padding: '0',
  };

  const liStyle = {
    margin: '0 10px',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: '#333',
    padding: '5px',
    fontWeight: 'bold', 
    fontSize: '17px',
  };

  const activeLiStyle = {
    backgroundColor: '#007bff',
    borderRadius: '15px',
    height: '80px', 
    width: '160px', 
  };

  const location = useLocation();

  const isTabActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav style={navStyle}>
      <ul style={ulStyle}>
        <li style={{ ...liStyle, ...(isTabActive('/UniversityPortal/CertificationManager') ? activeLiStyle : {}) }}>
          <NavLink
            to="/UniversityPortal/CertificationManager"
            style={linkStyle}
            exact
            className="tab-link"
          >
            <span style={linkStyle}>UNI:</span>
            <br /> Equivalences Manager
          </NavLink>
        </li>
        <li style={{ ...liStyle, ...(isTabActive('/BlockchainIntegration') ? activeLiStyle : {}) }}>
          <NavLink
            to="/BlockchainIntegration"
            style={linkStyle}
            exact
            className="tab-link"
          >
            <span style={linkStyle}>DEMO:</span>
            <br /> Verify Certification
          </NavLink>
          </li>
          <li style={{ ...liStyle, ...(isTabActive('/UniversityPortal/CourseManager') ? activeLiStyle : {}) }}>
          <NavLink
            to="/UniversityPortal/CourseManager"
            style={linkStyle}
            exact
            className="tab-link"
          >
            <span style={linkStyle}>UNI:</span><br /> Courses Manager
          </NavLink>
        </li>
        <li style={{ ...liStyle, ...(isTabActive('/UniversityPortal/UniqueEquivalences') ? activeLiStyle : {}) }}>
          <NavLink
            to="/UniversityPortal/UniqueEquivalences"
            style={linkStyle}
            exact
            className="tab-link"
          >
            <span style={linkStyle}>UNI:</span><br /> List Unique Equivalences
          </NavLink>
        </li>
        <li style={{ ...liStyle, ...(isTabActive('/UniversityPortal/CompanyManager') ? activeLiStyle : {}) }}>
          <NavLink
            to="/UniversityPortal/CompanyManager"
            style={linkStyle}
            exact
            className="tab-link"
          >
            <span style={linkStyle}>UNI:</span><br /> Company Manager
          </NavLink>
        </li>      
        <li style={{ ...liStyle, ...(isTabActive('/CompanyPortal/CertifyWorker') ? activeLiStyle : {}) }}>
          <NavLink
            to="/CompanyPortal/CertifyWorker"
            style={linkStyle}
            exact
            className="tab-link"
          >
            <span style={linkStyle}>Company:</span><br /> Certification Manager
          </NavLink>
        </li>           
      </ul>
    </nav>
  );
};

export default TabSelector;
