import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

const AcademicCertificationContainer = (props) => {
  const universityId = props.universityId;
  const excludedUniversityId = universityId;

  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificateId, setSelectedCertificateId] = useState("");
  const [equivalences, setEquivalences] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [selectedPickerUniversity, setSelectedPickerUniversity] = useState("");
  const [universities, setUniversities] = useState([]);

  const [courses2, setCourses2] = useState([]);
  const [selectedCourse2, setSelectedCourse2] = useState([]);

 
  useEffect(() => {
    fetchUniversities();
    fetchUsers(universityId);
    fetchCourses(universityId);
    //if(selectedUserId !== "") {
    //fetchCertificates(selectedUserId);
    //}
  }, []);

 
  //fetchers
  const fetchUniversities = async () => {
    try {
      const response = await axios.get("/read");
      setUniversities(response.data);
    } catch (error) {
      console.error("Error fetching universities:", error);
    }
  };

  const fetchUsers = async (universityId) => {
    try {
      const response = await axios.get(`/users/${universityId}`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchEquivalences = async (universityId, certificateId) => {
    try {
      const response = await axios.get(
        `/equivalences/${universityId}/${certificateId}`
      );
      setEquivalences(response.data);
    } catch (error) {
      console.error("Error fetching equivalences:", error);
    }
  };

  const fetchCertificates = async (userId) => {
    try {
      const response = await axios.get(`/certificates/${userId}`);
      setCertificates(response.data);
    } catch (error) {
      console.error("Error fetching certificates:", error);
    }
  };

  const fetchCourses = async (universityId) => {
    try {
      const response = await axios.get(`/courses/${universityId}`);
      setCourses(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const fetchCoursesTarget = async (universityId) => {
    try {
      const response = await axios.get(
        `/UniversityPortal/CoursesTarget/${universityId}`
      );
      setCourses2(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  //handlers
  const handleUserClick = async (userId) => {
    if (userId === selectedUserId) {
      setSelectedUserId("");
      setCertificates([]);
      //setProfessionalCertificates([]);
    } else {
      setSelectedUserId(userId);
      try {
        const certificatesResponse = await axios.get(`/certificates/${userId}`);
        setCertificates(certificatesResponse.data);
        //const professionalcertificatesResponse = await axios.get(`/professionalcertificates/${userId}`);
        //setProfessionalCertificates(professionalcertificatesResponse.data);
      } catch (error) {
        console.error("Error fetching certificates:", error);
      }
    }
  };

  const handleCertificateClick = async (
    selectedUniversityId,
    certificateId
  ) => {
    if (certificateId === selectedCertificateId) {
      setSelectedCertificateId("");
    } else {
      setSelectedCertificateId(certificateId);
      try {
        // Fetch equivalences for the selected certificate
        //const equivalencesResponse = await axios.get(`/equivalences/${selectedUniversityId}/${certificateId}`);
        // Store the equivalences data in a state variable
        //setEquivalences(equivalencesResponse.data);
        fetchCertificates(selectedUserId);
        fetchEquivalences(selectedUniversityId, certificateId);
      } catch (error) {
        console.error("Error fetching equivalences:", error);
      }
    }
  };

  const getFormattedDate = async () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleInsertCertificate = async (event) => {

    //ON THE INSERT, store the original insert in the state. originalInsert, setOriginalInsert
    //Pass this state to the BlockchainIntegration Component as the database prop?

    //Mutate the Certificate






    event.preventDefault();
    const responseCourseID = await axios.get(
      `/UniversityPortal/CourseId/${selectedCourse}`
    );
    const courseId = responseCourseID.data;

    const grade = event.target.elements.grade.value;
    const date = await getFormattedDate();

    try {
      const lastCertificateIdResponse = await fetch(
        `/last/certificate/${universityId}`,
        {
          method: "GET",
        }
      );

      if (!lastCertificateIdResponse.ok) {
        console.error(
          "Failed to fetch last certificate ID:",
          lastCertificateIdResponse.status
        );
        return;
      }

      const lastCertificateIdData = await lastCertificateIdResponse.json();
      const lastCertificateId = lastCertificateIdData.lastCertificateId;
      const newCertificateId = lastCertificateId + 1;
      console.log("last certificate ID:", lastCertificateId);

      const insertCertificateResponse = await fetch("/insert/certificate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          universityId,
          courseId,
          grade,
          date,
          certificateId: newCertificateId,
          userId: selectedUserId,
        }),
      });

      if (insertCertificateResponse.ok) {
        // Certificate inserted successfully, update the certificate list
        fetchCertificates(selectedUserId);
        event.target.reset();
      } else {
        console.error(
          "Failed to insert certificate:",
          insertCertificateResponse.status
        );
      }
    } catch (error) {
      console.error("Error inserting certificate:", error);
    }
  };

  const testStatus = async (certificateId) => {
    try {
         //I test the certificate id for 'invalid' -> return pop-up can't insert equivalence on invalid certificate
      const responseCertificateStatus = await axios.get(`/Certificate/Status/${certificateId}`);
      const CertificateStatus = responseCertificateStatus.data.Status;
      console.log("Certificate Status:" + CertificateStatus);
      return CertificateStatus;
    } catch (error) {
      console.error("Error fetching certificate status:", error);
    }
  };
  


  const invalidateResource = async (resourceType, ResourceId) => {
    try {
      switch(resourceType) {
        case "certificate":         
          const CertificateStatus = await testStatus(ResourceId);
          console.log("INVALIDATE Certificate Status:" + CertificateStatus);
          if (CertificateStatus === "Valid") {
            const invalidateCertificateResponse = await axios.put(`/InvalidateCertificate/${ResourceId}`);            
            // Certificate invalidated successfully, update the certificate list                  
          }            
          break;
        case "professional_certificate":
          break;
        case "default":
            // code block
          break;
      }
      //fetchCertificates(selectedUserId); 
    } catch (error) {
      console.error("Error invalidating certificate:", error);
    }
  }     

  const handleUpdateCertificate = async (event) => {
    event.preventDefault();
    const certificateId = selectedCertificateId;
    const newGrade = event.target.elements.grade2.value;
    
    try {
      await axios.put(`/BlockchainIntegration/newgrade/${certificateId}`, { newGrade });    
      await invalidateResource("certificate", certificateId);      

    } catch (error) {
      console.error('Error updating certificate:', error);
    }
    
    fetchCertificates(selectedUserId);
    event.target.reset();
    
  };

  const handleInsertEquivalence = async (event) => {
    event.preventDefault();
    
    const CertificateStatus = await testStatus(selectedCertificateId);
    if (CertificateStatus === "INVALID") {
      alert("You can't insert an Equivalence to an Invalid Certificate. A Mutation was detected.");      
    }
    else{
    //const sourceUniversityId = event.target.elements.sourceUniversityId.value;
    //const sourceCertificate = event.target.elements.sourceCertificate.value;
    const responseUniversityID = await axios.get(`/UniversityPortal/UniversityID/${selectedPickerUniversity}`);
    const targetUniversityId = responseUniversityID.data;

    const responseCourseID = await axios.get(`/UniversityPortal/CourseId/${selectedCourse2}`);
    const targetCourseId = responseCourseID.data;
    const date = await getFormattedDate();

    try {
      const lastEquivalenceIdResponse = await fetch(`/last/equivalence`, {
        method: "GET",
      });

      if (!lastEquivalenceIdResponse.ok) {
        console.error(
          "Failed to fetch last equivalence ID:",
          lastEquivalenceIdResponse.status
        );
        return;
      }

      const lastEquivalenceIdData = await lastEquivalenceIdResponse.json();
      const lastEquivalenceId = lastEquivalenceIdData.lastEquivalenceId;
      const newEquivalenceId = lastEquivalenceId + 1;
      console.log("last equivalence ID:", lastEquivalenceId);

      const insertEquivalenceResponse = await fetch("/insert/equivalence", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sourceUniversityId: universityId,
          sourceCertificate: selectedCertificateId,
          targetUniversityId,
          targetCourseId,
          date,
          equivalenceId: newEquivalenceId,
        }),
      });

      if (insertEquivalenceResponse.ok) {
        // Equivalence inserted successfully, update the equivalence list
        fetchEquivalences(universityId, selectedCertificateId);
        event.target.reset();
      } else {
        console.error(
          "Failed to insert equivalence:",
          insertEquivalenceResponse.status
        );
      }
    } catch (error) {
      console.error("Error inserting equivalence:", error);
    }

    }

    
  };

  const handlePickerUniversity = async (e) => {
    //console.log("E", e.target.value);
    setSelectedPickerUniversity(e.target.value);
    const response = await axios.get(`/UniversityPortal/UniversityID/${e.target.value}`);
    const targetUniversityId = response.data;
    //console.log("TARGET UNI ID IS: ", targetUniversityId);
    fetchCoursesTarget(targetUniversityId);
  };

  return (
    <div>
      <div className="section">
        <h1>List of University Associated Users</h1>
        <h1 className="description">Press an associated user to view their academic certificates</h1>
        <table className="table">
          <thead>
            <tr>
              <th>YachaY ID</th>
              {/* <th>Internal ID</th> */}
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr
                key={user.ID_YachaY_User}
                onClick={() => handleUserClick(user.ID_YachaY_User)}
                className={
                  selectedUserId === user.ID_YachaY_User ? "selected" : ""
                }
              >
                <td>{user.ID_YachaY_User}</td>
                {/* <td>{user.Internal_User_ID}</td> */}
                <td>{user.Name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      
      {selectedUserId && (
        <div className="section">
          <hr className="divider" />
          <h1>List of Academic Certificates for selected User</h1>
          <h1 className="description">Press an user academic certificate to manage its academic equivalences.</h1>
          {/* <div className="container">
            <div className="row">
              <div className="col">
                <label htmlFor="selectCourse" style={{ fontWeight: "bold" }}> Select Course:</label>
                <select
                  id="selectCourse"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                >
                  <option value="">-- Select Course --</option>
                  {courses.map((course) => (
                    <option key={course.id} value={course.Name}>
                      {course.Name}
                    </option>
                  ))}
                </select>
              </div>
              
            <form className="form" onSubmit={(e) => handleInsertCertificate(e)}>
            <div className="col">
              <label htmlFor="inputGrade" style={{ fontWeight: "bold" }}>Select Grade:</label>
              <input type="number" name="grade" placeholder="Grade" />
              </div>              
              <div className="row">
              <div className="col">
              <button type="submit">Certify User</button>
              </div>
              </div>
              </form>                                 
            </div>
          </div> */}
          <table className="table">
            <thead>
              <tr>
                <th>Certificate ID</th>
                {/* <th>Internal ID</th> */}
                <th>Course ID</th>
                <th>Course Name</th>
                <th>Grade</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {certificates.map((certificate) => (
                <tr
                  key={certificate.ID_YachaY_Certificate}
                  onClick={() =>
                    handleCertificateClick(
                      universityId,
                      certificate.ID_YachaY_Certificate
                    )
                  }
                  className={
                    selectedCertificateId === certificate.ID_YachaY_Certificate
                      ? "selected"
                      : ""
                  }
                >
                  <td>{certificate.ID_YachaY_Certificate}</td>
                  {/* <td>{certificate.ID_Internal_Certificate}</td> */}
                  <td>{certificate.ID_YachaY_Course}</td>
                  <td>{certificate.associatedCourseName}</td>
                  <td>{certificate.Grade}</td>
                  <td>{certificate.Date}</td>
                  <td className={certificate.Status === 'INVALID' ? 'red-status' : 'green-status'}>{certificate.Status}</td>
                </tr>
              ))}
            </tbody>
          </table>          
        </div>
      )}

      <hr className="divider" />
      {selectedCertificateId && (
        <div className="section">
          <h1>List of Academic Equivalences for selected Certificate</h1>
          <h1 className="description">Add equivalence from a certificate to a available course in the target university</h1>
          <form className="form" onSubmit={handleInsertEquivalence}>
          <div className="container">
            <div className="row">
              <div className="col">
                <label htmlFor="selectUniversity" className="label" style={{ fontWeight: "bold" }}>Select Target University:</label>
                <select
                  value={selectedPickerUniversity}
                  onChange={handlePickerUniversity}
                  className="select-input"
                >
                  <option value="">-- Select University --</option>
                {universities.map((university) => {
                  // Exclude the university with a specific ID
                  if (university.id !== excludedUniversityId) {
                    return (
                      <option key={university.id} value={university.Name}>
                        {university.Name}
                      </option>
                    );
                  } else {
                    return null; // Exclude this university from the options
                  }
                })}
              </select></div>
              <div className="col">
                <label htmlFor="selectCourse2" className="label" style={{ fontWeight: "bold" }}>Select Course:</label>
                <select
                  id="selectCourse2"
                  value={selectedCourse2}
                  onChange={(e) => setSelectedCourse2(e.target.value)}
                  className="select-input">
                  <option value="">-- Select Course --</option>
                {courses2.map((course2) => (
                  <option key={course2.id} value={course2.Name}>
                    {course2.Name}
                  </option>
                ))}
              </select>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button type="submit" className="add-button">Add Equivalence</button>
                </div>
              </div>
            </div>
          </form>
          <table className="table">
            <thead>
              <tr>
                <th>Equivalence ID</th>                
                <th>Source Name</th>                
                <th>Source Course Name</th>                
                <th>Target Name</th>                
                <th>Target Course Name</th>
                <th>Date</th>
              </tr>
            </thead>
            {equivalences && Array.isArray(equivalences) && (
              <tbody>
                {equivalences.map((equivalence) => (
                  <tr key={equivalence.ID_YachaY_Equivalence}>
                    <td>{equivalence.ID_YachaY_Equivalence}</td>                    
                    <td>{equivalence.associatedSourceName}</td>                    
                    <td>{equivalence.associatedSourceCourseName}</td>                   
                    <td>{equivalence.associatedTargetName}</td>                    
                    <td>{equivalence.associatedTargetCourseName}</td>
                    <td>{equivalence.Date}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      )}
    </div>
  );
};

const UniversityPortal_Certification = () => {

  const universityId = "1";
  const [universityName, setUniversityName] = useState("");

  useEffect(() => {
    fetchUniversityName();   
  }, []);

  const fetchUniversityName = async () => {
    try {
      const response = await axios.get(
        `/UniversityPortal/UniversityName/${universityId}`
      );
      setUniversityName(response.data);
    } catch (error) {
      console.error("Error fetching university name:", error);
    }
  };

  

  return (
    <div className="university-portal">
      <div className="container">
      <h1 className="subtitle">Welcome to the University YachaY Portal</h1>
        <div className="frame">
          <div className="frame-content">
            <h1 className="subtitle-uni">{universityName}</h1> 
          </div>
        </div>    
        <hr className="divider" />
      </div>
      <AcademicCertificationContainer universityId={universityId} />     
    </div>
  );
};

export default UniversityPortal_Certification;
