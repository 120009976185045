import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { json } from 'react-router-dom';




const BlockchainIntegration = () => {  

    //TODO: Substitute with actual generated hashes from the blockchain
    const [verification, setVerification] = useState('');
    const [hash1, setHash1] = useState('');
    const [hash2, setHash2] = useState('');  
    const [numberBlocks, setNumberBlocks] = useState(null);  
    const [lastInsertedCertificateId, setLastInsertedCertificateId] = useState(1);

    const [isAltered, setIsAltered] = useState(false);
    const [oldGrade, setOldGrade] = useState('');
    const [newGrade, setNewGrade] = useState('');
    const [currentCertificate, setCurrentCertificate] = useState(0);

    
    const handleUpdateCertificateGrade = async (event) => {
        event.preventDefault();        
        
        const responseLastCertificateInsertedID = await axios.get(`/BlockchainIntegration/lastInsertedCertificate/`);
        const LastCertificateInsertedID = await responseLastCertificateInsertedID.data.lastCertificateId;
        const certificateId = LastCertificateInsertedID;

        //store the old grade in the state
        const responseGradebyID = await axios.get(`/BlockchainIntegration/gradebyID/${certificateId}`);
        const GradebyID = await responseGradebyID.data.Grade;
        setOldGrade(GradebyID);

        const newGrade = event.target.elements.grade2.value;
        setNewGrade(newGrade);
        
        try {
        await axios.put(`/BlockchainIntegration/newgrade/${certificateId}`, { newGrade });    
        setIsAltered(true);
        await invalidateResource("certificate", certificateId);      

        } catch (error) {
        console.error('Error updating certificate:', error);
        }
        
        //fetchCertificates(selectedUserId);
        event.target.reset();        
    };

    const testStatus = async (certificateId) => {
        try {
             //I test the certificate id for 'invalid' -> return pop-up can't insert equivalence on invalid certificate
          const responseCertificateStatus = await axios.get(`/Certificate/Status/${certificateId}`);
          const CertificateStatus = responseCertificateStatus.data.Status;
          console.log("Certificate Status:" + CertificateStatus);
          return CertificateStatus;
        } catch (error) {
          console.error("Error fetching certificate status:", error);
        }
      };

    const invalidateResource = async (resourceType, ResourceId) => {
        try {
          switch(resourceType) {
            case "certificate":         
              const CertificateStatus = await testStatus(ResourceId);
              console.log("INVALIDATE Certificate Status:" + CertificateStatus);
              if (CertificateStatus === "Valid") {
                const invalidateCertificateResponse = await axios.put(`/InvalidateCertificate/${ResourceId}`);            
                // Certificate invalidated successfully, update the certificate list                  
              }            
              break;
            case "professional_certificate":
              break;
            case "default":
                // code block
              break;
          }
          //fetchCertificates(selectedUserId); 
        } catch (error) {
          console.error("Error invalidating certificate:", error);
        }
      }   

    //GET FROM DATABASE
    const DatabaseVerification = async (resourceType, resourceIdentification) => {
        //triggers when a new equivalence academic or professional is created in the database     
        let databaseData = null;
        try {     
            switch (resourceType) {
                case 'certificate':
                    const certificateResponse = await axios.get(`blockchain/db_verification/certificate/${resourceIdentification}}`);
                    databaseData = certificateResponse.data;
                    console.log(certificateResponse.data);
                    break;
                case 'equivalence':
                    const equivalenceResponse = await axios.get(`blockchain/db_verification/equivalence/${resourceIdentification}`);
                    databaseData = equivalenceResponse.data;
                    console.log(equivalenceResponse.data);
                    break;
                case 'job_certificate':                    
                    break;
                case 'professional_certificate':                    
                    break;
                case 'professional_equivalence':                    
                    break;
                case 'default':
                    databaseData = 'Error fetching data: resourceType not found';
                    console.log('Error fetching data:');
            }
      
           

            console.log("Database Data - Database Verification:" + JSON.stringify(databaseData, null, 2));
            //return JSON.stringify(databaseData, null, 2);
            return databaseData;
             
        } catch (error) {
        console.error('Error fetching data:', error);      
        }
    };

    //GET Blockchain Number of Blocks
    const handleGetNumberBlocks = async () => {
        let NumberBlocks = null;
        try {
            const response = await axios.get(`https://dltconnector.yachay.grisenergia.pt/blockchain/numberblocks`);
            NumberBlocks = response.data.NumberOfBlocks;
            console.log(NumberBlocks);
            setNumberBlocks(NumberBlocks);
        } catch (error) {
            console.error('Error fetching number of blockchain blocks:', error);
        }        
    };

     //----- GET Fom Blockchain -----//
     // https://dltconnector.yachay.grisenergia.pt/blockchain/validateresource
     //input resource jsonBody
     //output true or false

    const BlockchainVerification = async (json1, json2) => {       

        try {
            /* const response = await axios.get('/blockchain/get', {
              resourceType,
              sensitiveData: true,
              resourceVersion: 1,              
              resourceIdentification,
              jsonBody: {}
            });
 */         
            //This response is currently only true or false
            console.log("Input JSON body" + JSON.stringify(json1, null, 2));
            const response = await axios.post('https://dltconnector.yachay.grisenergia.pt/compareTwoHash', {               
                params:
                {                
                    json1,
                    json2,  
                }                
                });
        
            const blockchainData = await response.data.hash;            
            // Handle the blockchain data as desired
            console.log("Blockchain Data:" + blockchainData);
          
            return blockchainData;
          } catch (error) {
            console.error('Error fetching blockchain data:', error);
          }
        };

    //----- POST to Blockchain -----//
    //string resourceType, bool sensitiveData, int resourceVersion, string resourceIdentification, string jsonBody
    //resourceType = "certificate" or "equivalence" or "professional_certificate" or "professional_equivalence" or "job_certificate"
    //sensitiveData = true or false
    //resourceVersion = integer
    //resourceIdentification = ID_YachaY_'resourceType' (ex: ID_YachaY_certificate)
    //jsonBody = json with the certificate or equivalence or professional_certificate or professional_equivalence or job_certificate content
    
    const handlePostBlockchain = async (resourceType, sensitiveData, resourceVersion, resourceIdentification, jsonBody) => {
        let blockchainData = null;
        try {
            switch (resourceType) {
                case 'certificate':
                    const certificateResponse = await axios.post(`blockchain/post/certificate/${sensitiveData}/${resourceVersion}/${resourceIdentification}`, jsonBody);
                    blockchainData = certificateResponse.data;
                    console.log(certificateResponse.data);
                    break;
                case 'equivalence':
                    const equivalenceResponse = await axios.post(`blockchain/post/equivalence/${sensitiveData}/${resourceVersion}/${resourceIdentification}`, jsonBody);
                    blockchainData = equivalenceResponse.data;
                    console.log(equivalenceResponse.data);
                    break;
                case 'default':
                    blockchainData = 'Error fetching data: resourceType not found';
                    console.log('Error fetching data:');
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const computeHash = async (jsonBody) => {
        //console.log("Input JSON computeHash:", jsonBody);
        const computeHashResponse = await axios.post('https://dltconnector.yachay.grisenergia.pt/computehash', {               
            params:                  
                jsonBody                  
            });     
        const hash = await computeHashResponse.data.hash;
        console.log("Generate Hash DB" + hash);        
        return hash;
    };  

    const compareHashes = async (hash1_t, hash2_t) => {
        setHash1(hash1_t);
        setHash2(hash2_t);

        if (hash1_t === hash2_t) {
            setVerification(true); // Strings are the same
          } else {
            setVerification(false); // Strings are different
        }
    };  

    const handleVerificationButtonClick = async (resourceType) => {    
       
        //const Resource =  DatabaseVerification(resourceType, resourceIdentification);  
        const sensitiveData = true;
        const resourceVersion = 1;
        //console.log(Resource);

        const responseLastCertificateInsertedID = await axios.get(`/BlockchainIntegration/lastInsertedCertificate/`);
        const LastCertificateInsertedID = await responseLastCertificateInsertedID.data.lastCertificateId;
        setLastInsertedCertificateId(LastCertificateInsertedID);
        setCurrentCertificate(LastCertificateInsertedID);
        console.log("LastCertificateInsertedID:" + LastCertificateInsertedID);


        handleGetNumberBlocks();

        //handlePostBlockchain(resourceType, sensitiveData, resourceVersion, resourceIdentification, Resource);

     
        const databaseData = await DatabaseVerification(resourceType, LastCertificateInsertedID);      
        delete databaseData.Status; 
        console.log("Database JsonBody:" + databaseData);
        //const databaseData = {"ID_YachaY_Certificate": 1, "ID_Internal_Certificate": 1, "ID_YachaY_University": 1, "ID_YachaY_User": 1, "Internal_User_ID": 1, "ID_YachaY_Course": 7, "Internal_Course_ID": 7, "Grade": 95, "Date": "2022-05-14T23:00:00.000Z"};
        

        const hash1_t = await computeHash(databaseData);
        console.log("Hash1_t:" + hash1_t + databaseData);

               
        const databaseSection = document.getElementById('json-section-database');
        databaseSection.innerText = JSON.stringify(databaseData, null, 2);
        
        //Change the database data to the original grade. Blockchain remains with the last inserted that already has the new certificate alteration
        console.log("Old Grade:" + oldGrade);
        if(isAltered && oldGrade !== '') {
        databaseData.Grade = oldGrade; // Assign a new value to Grade        
        }
        const demoBlockchainData = databaseData;                 
        const JSONdemoBlockchainData = JSON.stringify(demoBlockchainData, null, 2);             
        const blockchainSection = document.getElementById('json-section-blockchain');        
        blockchainSection.innerText = JSONdemoBlockchainData;
           
        const hash2_t = await computeHash(demoBlockchainData);
        console.log("Hash2_t:" + hash2_t + demoBlockchainData);            
        
        //TODO: comment this if the /blockchain/validateresource endpoint is working for generic case
        compareHashes(hash1_t, hash2_t);

     /* 
        //returns true or false if the hashes are the same
        const verificationFlag = await BlockchainVerification(databaseData, demoBlockchainData);
        console.log("Blockchain Hash Verification:" + verificationFlag);        
        setVerification(verificationFlag);
        setHash1(hash1_t);
        setHash2(hash2_t);  */
     

        setIsAltered(false); //verification over, reseting
        //setNewGrade(''); //verification over, reseting

    };
    


    return (
        <div className="container">   
            <h1>Certificate Blockchain Verification</h1>     
            <h1 className="description">Press 'Verify Mutation' to compare what is stored in the Database VS what is stored in the Blockchain</h1>      
            <div className="side-by-side2">                  
                <form className="form" onSubmit={(e) => {
                    e.preventDefault();
                    /* handleVerificationButtonClick('certificate', 1); */
                    handleVerificationButtonClick('certificate');
                }}>                       
                    <button type="submit">Verify Mutation</button>
                </form>             
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {verification !== true && verification !== false ? (
                    <div></div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                    {verification ? (
                        <>                        
                        <h2 style={{ marginRight: '10px' }}>No Mutation</h2>
                        <AiOutlineCheckCircle style={{ color: 'green', fontSize: '50px' }} />                       
                        </>
                    ) : (
                        <>
                        <h2 style={{ marginRight: '10px' }}>Mutation Detected</h2>
                        <AiOutlineCloseCircle style={{ color: 'red', fontSize: '50px' }} />
                        </>
                    )}
                    </div>
                )}
                </div>      
            </div>
            <div className="container-bch">
            <div className="side-by-side">  
                <div className="Json-resource-container">   
                    <h1>Database Hash:</h1>                    
                    <h3 className={`small-heading ${verification ? 'green' : 'red'}`}>{hash1}</h3>
                    <div id="json-section-database" class="json-section"></div> 
                </div>
                <div className="Json-resource-container">  
                <h1>Blockchain Hash:</h1>
                <h3 className={`small-heading ${verification ? 'green' : 'red'}`}>{hash2}</h3>
                <div id="json-section-blockchain" class="json-section"></div>  
                </div>
            </div>                        
            </div>
            {!verification && (oldGrade !=='') && (<h2 style={{ textAlign: 'center', color: 'red' }}>Certificate ID#{currentCertificate} will be invalidated!</h2>)}  
            <div className="divider">
            <form className="form" onSubmit={(e) => handleUpdateCertificateGrade(e)} style={{ margin: '40px 0' }}>
            <label htmlFor="inputGrade" style={{ fontWeight: 'bold', marginBottom: '10px', display: 'block' }}>
              New Grade:  </label>
            <input type="number" name="grade2" placeholder="Grade" style={{ marginBottom: '10px', padding: '5px' }} />
            <button type="submit" className="btn-update" style={{ padding: '10px' }}>Unauthorized Alteration</button>
            </form>
            <div className="side-by-side-bnumber">
                <h2>Number of Network Blocks:</h2>
                <div className="square">{numberBlocks}</div>
            </div>
            </div>         
        </div>
    );
};



export default BlockchainIntegration;

