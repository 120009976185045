import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';


const CompanyPortal = () => {  
    const [companyName, setCompanyName] = useState('');
    const [companyWorkers, setCompanyWorkers] = useState([]);
    const [companyJobs, setCompanyJobs] = useState([]);

    const [userIdentifier, setUserIdentifier] = useState('');
    const [selectedJobPosition, setSelectedJobPosition] = useState('');
    
    const [userName, setUserName] = useState('');

    const universityId = 1;
    const companyId = 1;

    useEffect(() => {
        //setUniversityId(1);
        fetchCompanyName();
        fetchCompanyWorkers();
        fetchCompanyJobs();        
    }, []);

    const fetchCompanyName = async () => {
        try {
        const response = await axios.get(`/CompanyPortal/CompanyName/${companyId}`);        
        setCompanyName(response.data);
        } catch (error) {
        console.error('Error fetching company name:', error);
        }
    };  

    const fetchCompanyWorkers = async () => {
        try {
        const response = await axios.get(`/CompanyPortal/Workers/${universityId}`);
        setCompanyWorkers(response.data);
        } catch (error) {
        console.error('Error fetching company workers:', error);
        }
    };  

    const fetchCompanyJobs = async () => {
        try {
        const response = await axios.get(`/jobs/${companyId}`);
        setCompanyJobs(response.data);
        console.log("Company Jobs", companyJobs);
        } catch (error) {
        console.error('Error fetching company jobs:', error);
        }
    };

    const getFormattedDate = async () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    useEffect(() => {
        const fetchUserName = async () => {
            try {
                const response = await axios.get(`/user/${userIdentifier}`);
                setUserName(response.data);
            } catch (error) {
                console.error('Error fetching user name:', error);
                setUserName('');
            }
        };
    
        if (userIdentifier) {
            fetchUserName();
        } else {
            setUserName(''); // Reset the user name if the user identifier is empty
        }
    }, [userIdentifier]);

    const handleInsertCompanyProfessionalCertificate = async () => {
        //event.preventDefault();
        //select the job certificate associated with the job position        
        const responseJobCertificateID = await axios.get(`/CompanyPortal/JobCertificateId/${selectedJobPosition}`);        
        const jobCertificateId = responseJobCertificateID.data;   
        
        const responseCourseID = await axios.get(`/CompanyPortal/CourseId/${jobCertificateId}`); 
        const courseId = responseCourseID.data;

        const grade = 100; // TODO: Decide if grade should be a user input and if necessary
        const date = await getFormattedDate();
      
        try {
          const lastCertificateIdResponse = await fetch(`/last/professionalcertificates/${universityId}`, {
            method: 'GET',
          });
      
          if (!lastCertificateIdResponse.ok) {
            console.error('Failed to fetch last professional certificate ID:', lastCertificateIdResponse.status);
            return;
          }
      
          const lastCertificateIdData = await lastCertificateIdResponse.json();
          const lastCertificateId = lastCertificateIdData.lastCertificateId;
          const newCertificateId = lastCertificateId + 1;
          console.log('last professional certificate ID:', lastCertificateId);
      
          await axios.post('/insert/professionalcertificates', {
            universityId: universityId,
            courseId,
            grade,
            date,
            jobCertificateId,
            certificateId: newCertificateId,
            userId: userIdentifier,
          });
      
          
         // Certificate inserted successfully, update the certificate list
          fetchCompanyWorkers();            
        
        } catch (error) {
          console.error('Error inserting professional certificate:', error);
        }
      }; 
    
    const handleInsertCompanyProfessionalEquivalence = async () => {      
      //get the YachaY ID of the professional Certificate that was last inserted and get that Certificate info      
      const responseLastProfessionalCertificate = await axios.get(`/CompanyPortal/LastProfessionalCertificate`);
      const lastProfessionalCertificate = responseLastProfessionalCertificate.data;

      const sourceUniversityId = universityId;
      const targetUniversityId = universityId;

      const sourceCertificate = lastProfessionalCertificate.ID_YachaY_Professional_Certificate;
      const targetCourseId = lastProfessionalCertificate.ID_YachaY_Course;
      const date = await getFormattedDate();
      try {   
        const insertEquivalenceResponse = await fetch('/insert/professionalequivalences', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({        
            sourceUniversityId,
            sourceCertificate,
            targetUniversityId,
            targetCourseId,
            date,            
          }),
        });       
      } catch (error) {
        console.error('Error inserting professional equivalence (CompanyPortal):', error);
      }
    };
    

    const handleCertifyWorker = async (e) => {
        e.preventDefault();
        try {
        // Send the userIdentifier and selectedJobPosition to the server to handle the certification process
        //TODO: AUTO-GENERATE THE PROFESSIONAL CERTIFICATE       
            await handleInsertCompanyProfessionalCertificate();
        //TODO: POST TO BCHAIN Professional_Certificate
        
        //TODO: AUTO-GENERATE THE PROFESSIONAL EQUIVALENCE
            await handleInsertCompanyProfessionalEquivalence();
        //TODO: POST TO BCHAIN Professional_Equivalence

        // Update the companyWorkers state with the updated list of workers
        //fetchCompanyWorkers();
        // Reset the input fields
            setUserIdentifier('');
            setSelectedJobPosition('');
        } catch (error) {
            console.error('Error certifying worker:', error);
        }
    };


    
    return (
        <div className="container">
          <h1 className="subtitle">Welcome to the Company YachaY Portal</h1>
            <div className="frame">
                <div className="frame-content">
                <h1 className="subtitle-uni">{companyName}</h1>   
                </div>
            </div>  
          <hr className="divider" />
    
          <div className="section">
            <h1>List of Certified Company Workers</h1>
            <h1 className="description">Review users that have professional certificates associated with this company available job certificates</h1>
            <table className="table">
              <thead>
                <tr>
                  <th>YachaY ID</th>
                  <th>Internal ID</th>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Job Certificate</th>
                </tr>
              </thead>
              <tbody>
                {companyWorkers.map((companyWorker) => (
                  <tr key={companyWorker.ID_YachaY_User}>
                    <td>{companyWorker.ID_YachaY_User}</td>
                    <td>{companyWorker.Internal_User_ID}</td>
                    <td>{companyWorker.Name}</td>
                    <td>{companyWorker.jobName}</td>
                    <td>{companyWorker.jobCertificate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
    
          <hr className="divider" />
    
          <div className="section">
            <h1>Awarding Certification to a Worker</h1>
            <h1 className="description">Insert the worker YachaY identifier and choose their job position to professionally certify this user</h1>
            <div className="input-container">
                <div className="input-group">
                <label htmlFor="userIdentifier" style={{ fontWeight: 'bold' }}>YachaY User Identifier: </label>
                <input
                    type="number"
                    id="userIdentifier"
                    value={userIdentifier}
                    onChange={(e) => setUserIdentifier(e.target.value)}
                />
                </div>
                <div className="input-group">                
                <p>{userName && userName}</p>
                </div>
            </div>
            <div className="input-container">
                <label htmlFor="selectJobPosition" style={{ fontWeight: 'bold' }}>Select Job Position:</label>
                <select
                id="selectJobPosition"
                value={selectedJobPosition}
                onChange={(e) => setSelectedJobPosition(e.target.value)}
                >
                <option value="">-- Select Job Position --</option>
                {companyJobs.map((job) => (
                    <option key={job.id} value={job.Name}>
                    {job.Name}
                    </option>
                ))}
                </select>                
            </div>
            <form className="form" onSubmit={(e) => handleCertifyWorker(e)}>                         
              <button type="submit">Certify New Worker</button>
            </form>
            
        </div>
        </div>
      );
    };
    
   

export default CompanyPortal;

    